<template>
  <router-view />
</template>

<script>
export default {
  name: 'dispatch',
  components: {},
  data() {
    return {
    }
  },
  created() {
  }
}
</script>

<style lang="less" scoped>
</style>
